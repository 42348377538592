<template>
  <div class="chart-contain">
    <h2>
      <span class="chart-date">{{date}}</span>
      <div class="chart-list fr">
        <span>目标有效消耗：<em>{{ target }}kcal</em></span>
        <span>总消耗：<em>{{ all }}kcal</em></span>
        <span>总有效消耗量：<em>{{all_effective}}kcal</em></span>
        <span>总运动步数：<em>{{all_step}}步</em></span>
        <span>总运动时长：<em>{{all_time}}min</em></span>
      </div>
    </h2>
    <slot></slot>
  </div>
</template>
<script type='text/ecmascript-6'>
export default {
	props: ['date', 'all_effective', 'all_step', 'all_time', 'target', 'all'],
};
</script>
<style lang='css' scoped>
.chart-contain h2 {
  height: 35px;
  line-height: 35px;
  background: #f1f1f1;
  border: 1px solid #e3e3e3;
  font-size: 14px;
  padding: 0 15px;
}
.chart-date {color: #ff8400;}
.chart-list span {
  padding-right: 15px;
  color: #999;
}
.chart-list span em {
  color: #333;
}
</style>

<template>
  <div class="scheme-contain">
    <div class="data-contain">
      <div class="card-wrapper">
        <div class="monitor-search">
          <div class="monitor-date">
            <Date-picker format="yyyy-MM-dd" :value="sportsData.select_date" type="date" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
          </div>
          <div class="tab-contain">
            <router-link class="static-button" :to="{name: 'Sports'}">统计显示</router-link>
            <router-link :to="{name: 'SportsTable'}">数据显示</router-link>
            <router-link class="chart-button" :to="{name: 'SportsChart'}">图表显示</router-link>
          </div>
        </div>
        <h5 class="chart-info">注：该图表只显示app上传数据信息</h5>
      </div>
      <app-chart :date="date1" :all_effective="effect1" :all_step="step1" :all_time="totaltime1" :target="target1" :all="all1"><div class="chart" ref="chart1"></div></app-chart>
      <app-chart :date="date2" :all_effective="effect2" :all_step="step2" :all_time="totaltime2" :target="target2" :all="all2"><div class="chart" ref="chart2"></div></app-chart>
      <app-chart :date="date3" :all_effective="effect3" :all_step="step3" :all_time="totaltime3" :target="target3" :all="all3"><div class="chart" ref="chart3"></div></app-chart>
      <app-chart :date="date4" :all_effective="effect4" :all_step="step4" :all_time="totaltime4" :target="target4" :all="all4"><div class="chart" ref="chart4"></div></app-chart>
      <app-chart :date="date5" :all_effective="effect5" :all_step="step5" :all_time="totaltime5" :target="target5" :all="all5"><div class="chart" ref="chart5"></div></app-chart>
      <app-chart :date="date6" :all_effective="effect6" :all_step="step6" :all_time="totaltime6" :target="target6" :all="all6"><div class="chart" ref="chart6"></div></app-chart>
      <app-chart :date="date7" :all_effective="effect7" :all_step="step7" :all_time="totaltime7" :target="target7" :all="all7"><div class="chart" ref="chart7"></div></app-chart>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
import echarts from 'echarts';
import MemberService from '@/services/memberService';
import appChart from 'pages/member/member-detail/_sports';
export default {
	data() {
		return {
			sportsData: {
				index: 2,
				select_date: '',
				page: 1,
				size: 10,
			},
			sportsList: [],
			timeData1: [],
			timeData2: [],
			timeData3: [],
			timeData4: [],
			timeData5: [],
			timeData6: [],
			timeData7: [],
			totalStepList1: [],
			totalStepList2: [],
			totalStepList3: [],
			totalStepList4: [],
			totalStepList5: [],
			totalStepList6: [],
			totalStepList7: [],
			totalEffective1: [],
			totalEffective2: [],
			totalEffective3: [],
			totalEffective4: [],
			totalEffective5: [],
			totalEffective6: [],
			totalEffective7: [],
			Chart1: '',
			Chart2: '',
			Chart3: '',
			Chart4: '',
			Chart5: '',
			Chart6: '',
			Chart7: '',
			date1: '',
			date2: '',
			date3: '',
			date4: '',
			date5: '',
			date6: '',
			date7: '',
			effect1: '',
			effect2: '',
			effect3: '',
			effect4: '',
			effect5: '',
			effect6: '',
			effect7: '',
			step1: '',
			step2: '',
			step3: '',
			step4: '',
			step5: '',
			step6: '',
			step7: '',
			totaltime1: '',
			totaltime2: '',
			totaltime3: '',
			totaltime4: '',
			totaltime5: '',
			totaltime6: '',
			totaltime7: '',
			min1: '',
			min2: '',
			min3: '',
			min4: '',
			min5: '',
			min6: '',
			min7: '',
			max1: '',
			max2: '',
			max3: '',
			max4: '',
			max5: '',
			max6: '',
			max7: '',
			target1: '',
			target2: '',
			target3: '',
			target4: '',
			target5: '',
			target6: '',
			target7: '',
			all1: '',
			all2: '',
			all3: '',
			all4: '',
			all5: '',
			all6: '',
			all7: '',
			bloodTime1: [],
			bloodTime2: [],
			bloodTime3: [],
			bloodTime4: [],
			bloodTime5: [],
			bloodTime6: [],
			bloodTime7: [],
			heart1: [],
			heart2: [],
			heart3: [],
			heart4: [],
			heart5: [],
			heart6: [],
			heart7: [],
			diastolic1: [],
			diastolic2: [],
			diastolic3: [],
			diastolic4: [],
			diastolic5: [],
			diastolic6: [],
			diastolic7: [],
			systolic1: [],
			systolic2: [],
			systolic3: [],
			systolic4: [],
			systolic5: [],
			systolic6: [],
			systolic7: [],
		};
	},
	components: {
		'app-chart': appChart,
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		initData() {
			this.timeData1 = [];
			this.timeData2 = [];
			this.timeData3 = [];
			this.timeData4 = [];
			this.timeData5 = [];
			this.timeData6 = [];
			this.timeData7 = [];
			this.date1 = '';
			this.date2 = '';
			this.date3 = '';
			this.date4 = '';
			this.date5 = '';
			this.date6 = '';
			this.date7 = '';
			this.totalStepList1 = [];
			this.totalStepList2 = [];
			this.totalStepList3 = [];
			this.totalStepList4 = [];
			this.totalStepList5 = [];
			this.totalStepList6 = [];
			this.totalStepList7 = [];
			this.totalEffective1 = [];
			this.totalEffective2 = [];
			this.totalEffective3 = [];
			this.totalEffective4 = [];
			this.totalEffective5 = [];
			this.totalEffective6 = [];
			this.totalEffective7 = [];
			this.min1 = '';
			this.min2 = '';
			this.min3 = '';
			this.min4 = '';
			this.min5 = '';
			this.min6 = '';
			this.min7 = '';
			this.max1 = '';
			this.max2 = '';
			this.max3 = '';
			this.max4 = '';
			this.max5 = '';
			this.max6 = '';
			this.max7 = '';
			this.target1 = '';
			this.target2 = '';
			this.target3 = '';
			this.target4 = '';
			this.target5 = '';
			this.target6 = '';
			this.target7 = '';
			this.all1 = '';
			this.all2 = '';
			this.all3 = '';
			this.all4 = '';
			this.all5 = '';
			this.all6 = '';
			this.all7 = '';
			this.bloodTime1 = [];
			this.bloodTime2 = [];
			this.bloodTime3 = [];
			this.bloodTime4 = [];
			this.bloodTime5 = [];
			this.bloodTime6 = [];
			this.bloodTime7 = [];
			this.systolic1 = [];
			this.systolic2 = [];
			this.systolic3 = [];
			this.systolic4 = [];
			this.systolic5 = [];
			this.systolic6 = [];
			this.systolic7 = [];
			this.diastolic1 = [];
			this.diastolic2 = [];
			this.diastolic3 = [];
			this.diastolic4 = [];
			this.diastolic5 = [];
			this.diastolic6 = [];
			this.diastolic7 = [];
			this.heart1 = [];
			this.heart2 = [];
			this.heart3 = [];
			this.heart4 = [];
			this.heart5 = [];
			this.heart6 = [];
			this.heart7 = [];
		},
		handleInit() {
			this.sportsData.member_id = this.memberId;
			this.initData();
			MemberService.sportsQuery(this.sportsData).then((data) => {
				this.sportsList = data.data_show;

				this.date1 = this.sportsList[6].date;
				this.date2 = this.sportsList[5].date;
				this.date3 = this.sportsList[4].date;
				this.date4 = this.sportsList[3].date;
				this.date5 = this.sportsList[2].date;
				this.date6 = this.sportsList[1].date;
				this.date7 = this.sportsList[0].date;

				this.effect1 = this.sportsList[6].all_effective;
				this.effect2 = this.sportsList[5].all_effective;
				this.effect3 = this.sportsList[4].all_effective;
				this.effect4 = this.sportsList[3].all_effective;
				this.effect5 = this.sportsList[2].all_effective;
				this.effect6 = this.sportsList[1].all_effective;
				this.effect7 = this.sportsList[0].all_effective;

				this.step1 = this.sportsList[6].all_step;
				this.step2 = this.sportsList[5].all_step;
				this.step3 = this.sportsList[4].all_step;
				this.step4 = this.sportsList[3].all_step;
				this.step5 = this.sportsList[2].all_step;
				this.step6 = this.sportsList[1].all_step;
				this.step7 = this.sportsList[0].all_step;

				this.totaltime1 = this.sportsList[6].all_time;
				this.totaltime2 = this.sportsList[5].all_time;
				this.totaltime3 = this.sportsList[4].all_time;
				this.totaltime4 = this.sportsList[3].all_time;
				this.totaltime5 = this.sportsList[2].all_time;
				this.totaltime6 = this.sportsList[1].all_time;
				this.totaltime7 = this.sportsList[0].all_time;

				this.min1 = this.sportsList[6].min;
				this.min2 = this.sportsList[5].min;
				this.min3 = this.sportsList[4].min;
				this.min4 = this.sportsList[3].min;
				this.min5 = this.sportsList[2].min;
				this.min6 = this.sportsList[1].min;
				this.min7 = this.sportsList[0].min;

				this.max1 = this.sportsList[6].max;
				this.max2 = this.sportsList[5].max;
				this.max3 = this.sportsList[4].max;
				this.max4 = this.sportsList[3].max;
				this.max5 = this.sportsList[2].max;
				this.max6 = this.sportsList[1].max;
				this.max7 = this.sportsList[0].max;

				this.target1 = this.sportsList[6].target;
				this.target2 = this.sportsList[5].target;
				this.target3 = this.sportsList[4].target;
				this.target4 = this.sportsList[3].target;
				this.target5 = this.sportsList[2].target;
				this.target6 = this.sportsList[1].target;
				this.target7 = this.sportsList[0].target;

				this.all1 = this.sportsList[6].all;
				this.all2 = this.sportsList[5].all;
				this.all3 = this.sportsList[4].all;
				this.all4 = this.sportsList[3].all;
				this.all5 = this.sportsList[2].all;
				this.all6 = this.sportsList[1].all;
				this.all7 = this.sportsList[0].all;

				this.sportsList[6].blood_arr.forEach((item) => {
					this.bloodTime1.push(item.time);
					this.heart1.push(item.heart);
					this.diastolic1.push(item.diastolic);
					this.systolic1.push(item.systolic);
				});

				this.sportsList[5].blood_arr.forEach((item) => {
					this.bloodTime2.push(item.time);
					this.heart2.push(item.heart);
					this.diastolic2.push(item.diastolic);
					this.systolic2.push(item.systolic);
				});

				this.sportsList[4].blood_arr.forEach((item) => {
					this.bloodTime3.push(item.time);
					this.heart3.push(item.heart);
					this.diastolic3.push(item.diastolic);
					this.systolic3.push(item.systolic);
				});

				this.sportsList[3].blood_arr.forEach((item) => {
					this.bloodTime4.push(item.time);
					this.heart4.push(item.heart);
					this.diastolic4.push(item.diastolic);
					this.systolic4.push(item.systolic);
				});

				this.sportsList[2].blood_arr.forEach((item) => {
					this.bloodTime5.push(item.time);
					this.heart5.push(item.heart);
					this.diastolic5.push(item.diastolic);
					this.systolic5.push(item.systolic);
				});

				this.sportsList[1].blood_arr.forEach((item) => {
					this.bloodTime6.push(item.time);
					this.heart6.push(item.heart);
					this.diastolic6.push(item.diastolic);
					this.systolic6.push(item.systolic);
				});

				this.sportsList[0].blood_arr.forEach((item) => {
					this.bloodTime7.push(item.time);
					this.heart7.push(item.heart);
					this.diastolic7.push(item.diastolic);
					this.systolic7.push(item.systolic);
				});

				this.sportsList[6].time7.forEach((time) => {
					this.timeData1.push(time.time);
					this.totalStepList1.push(time.total_step);
					this.totalEffective1.push(time.total_effective);
				});
				this.sportsList[5].time6.forEach((time) => {
					this.timeData2.push(time.time);
					this.totalStepList2.push(time.total_step);
					this.totalEffective2.push(time.total_effective);
				});
				this.sportsList[4].time5.forEach((time) => {
					this.timeData3.push(time.time);
					this.totalStepList3.push(time.total_step);
					this.totalEffective3.push(time.total_effective);
				});
				this.sportsList[3].time4.forEach((time) => {
					this.timeData4.push(time.time);
					this.totalStepList4.push(time.total_step);
					this.totalEffective4.push(time.total_effective);
				});
				this.sportsList[2].time3.forEach((time) => {
					this.timeData5.push(time.time);
					this.totalStepList5.push(time.total_step);
					this.totalEffective5.push(time.total_effective);
				});
				this.sportsList[1].time2.forEach((time) => {
					this.timeData6.push(time.time);
					this.totalStepList6.push(time.total_step);
					this.totalEffective6.push(time.total_effective);
				});
				this.sportsList[0].time1.forEach((time) => {
					this.timeData7.push(time.time);
					this.totalStepList7.push(time.total_step);
					this.totalEffective7.push(time.total_effective);
				});
				this.loadChart();
			});
		},
		optionsInit() {
			// 初始化实例
			let options = {
				title: {
					text: '',
					textStyle: {
						color: '#424e67',
						fontSize: '16',
					},
					padding: 25,
				},
				backgroundColor: '#fff',
				grid: {
					bottom: 80,
				},
				color: ['#f18985', '#32d2c9', '#f8a20f', '#5886e6'],
				legend: {
					data: ['有效消耗量', '收缩压', '舒张压', '心率'],
					x: 'right',
					orient: 'vertical',
					padding: 25,
					textStyle: {
						color: '#666',
						fontSize: 12,
					},
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: true,
						axisLine: { onZero: false },
						splitLine: {
							show: false,
						},
						data: [],
						// data: ['05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00']
					},
					{
						type: 'category',
						boundaryGap: true,
						axisLine: { onZero: false },
						splitLine: {
							show: false,
						},
						min: 0.01,
						data: [],
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '(单位/KCal)',
						max: '25',
						scale: true,
						boundaryGap: [0.2, 0.2],
						splitLine: {
							show: false,
						},
					},
					{
						type: 'value',
						name: '(单位/mmHg)',
						scale: true,
						boundaryGap: [0.2, 0.2],
						splitLine: {
							show: false,
						},
					},
				],
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						animation: false,
						label: {
							backgroundColor: '#505765',
						},
					},
				},
				dataZoom: [
					{
						show: true,
						type: 'inside',
						xAxisIndex: [0, 1],
					},
					{
						type: 'slider',
						show: true,
						xAxisIndex: [0, 1],
					},
				],
				series: [
					{
						name: '有效消耗量',
						type: 'line',
						smooth: true,
						animation: false,
						areaStyle: {
							normal: {
								color: 'transparent',
							},
						},
						lineStyle: {
							normal: {
								width: 1,
								color: '#a9334c',
							},
						},
						itemStyle: {
							normal: {
								lineStyle: {
									color: '#fff',
								},
							},
						},
						symbolSize: 5,
						showSymbol: true,
						data: [],
					},
					{
						name: '收缩压',
						type: 'scatter',
						xAxisIndex: 1,
						yAxisIndex: 1,
						symbolSize: 10,
						animation: false,
						data: [],
					},
					{
						name: '舒张压',
						type: 'scatter',
						xAxisIndex: 1,
						yAxisIndex: 1,
						symbolSize: 10,
						animation: false,
						data: [],
					},
					{
						name: '心率',
						type: 'scatter',
						xAxisIndex: 1,
						yAxisIndex: 1,
						symbolSize: 10,
						animation: false,
						data: [],
					},
				],
			};
			return options;
		},
		loadChart() {
			this.Chart1.hideLoading();
			this.Chart1.setOption({
				xAxis: [
					{
						data: this.timeData1,
					},
					{
						data: this.bloodTime1,
					},
				],
				series: [
					{
						// 根据名字对应到相应的系列
						name: '有效消耗量',
						data: this.totalEffective1,
						markArea: {
							data: [
								[
									{
										name: '有效运动区间',
										yAxis: this.max1,
										label: {
											normal: {
												position: ['50%', '40%'],
												textStyle: {
													color: '#333',
												},
											},
										},
										itemStyle: {
											normal: {
												borderColor: '#fdad00',
												borderWidth: '1',
												borderType: 'dashed',
												color: '#fff',
											},
										},
									},
									{
										yAxis: this.min1,
									},
								],
							],
						},
					},
					{
						name: '收缩压',
						data: this.systolic1,
					},
					{
						name: '舒张压',
						data: this.diastolic1,
					},
					{
						name: '心率',
						data: this.heart1,
					},
				],
			});
			this.Chart2.hideLoading();
			this.Chart2.setOption({
				xAxis: [
					{
						data: this.timeData2,
					},
					{
						data: this.bloodTime2,
					},
				],
				series: [
					{
						// 根据名字对应到相应的系列
						name: '有效消耗量',
						data: this.totalEffective2,
						markArea: {
							data: [
								[
									{
										name: '有效运动区间',
										yAxis: this.max2,
										label: {
											normal: {
												position: ['50%', '40%'],
												textStyle: {
													color: '#333',
												},
											},
										},
										itemStyle: {
											normal: {
												borderColor: '#fdad00',
												borderWidth: '1',
												borderType: 'dashed',
												color: '#fff',
											},
										},
									},
									{
										yAxis: this.min2,
									},
								],
							],
						},
					},
					{
						name: '收缩压',
						data: this.systolic2,
					},
					{
						name: '舒张压',
						data: this.diastolic2,
					},
					{
						name: '心率',
						data: this.heart2,
					},
				],
			});
			this.Chart3.hideLoading();
			this.Chart3.setOption({
				xAxis: [
					{
						data: this.timeData3,
					},
					{
						data: this.bloodTime3,
					},
				],
				series: [
					{
						// 根据名字对应到相应的系列
						name: '有效消耗量',
						data: this.totalEffective3,
						markArea: {
							data: [
								[
									{
										name: '有效运动区间',
										yAxis: this.max3,
										label: {
											normal: {
												position: ['50%', '40%'],
												textStyle: {
													color: '#333',
												},
											},
										},
										itemStyle: {
											normal: {
												borderColor: '#fdad00',
												borderWidth: '1',
												borderType: 'dashed',
												color: '#fff',
											},
										},
									},
									{
										yAxis: this.min3,
									},
								],
							],
						},
					},
					{
						name: '收缩压',
						data: this.systolic3,
					},
					{
						name: '舒张压',
						data: this.diastolic3,
					},
					{
						name: '心率',
						data: this.heart3,
					},
				],
			});
			this.Chart4.hideLoading();
			this.Chart4.setOption({
				xAxis: [
					{
						data: this.timeData4,
					},
					{
						data: this.bloodTime4,
					},
				],
				series: [
					{
						// 根据名字对应到相应的系列
						name: '有效消耗量',
						data: this.totalEffective4,
						markArea: {
							data: [
								[
									{
										name: '有效运动区间',
										yAxis: this.max4,
										label: {
											normal: {
												position: ['50%', '40%'],
												textStyle: {
													color: '#333',
												},
											},
										},
										itemStyle: {
											normal: {
												borderColor: '#fdad00',
												borderWidth: '1',
												borderType: 'dashed',
												color: '#fff',
											},
										},
									},
									{
										yAxis: this.min4,
									},
								],
							],
						},
					},
					{
						name: '收缩压',
						data: this.systolic4,
					},
					{
						name: '舒张压',
						data: this.diastolic4,
					},
					{
						name: '心率',
						data: this.heart4,
					},
				],
			});
			this.Chart5.hideLoading();
			this.Chart5.setOption({
				xAxis: [
					{
						data: this.timeData5,
					},
					{
						data: this.bloodTime5,
					},
				],
				series: [
					{
						// 根据名字对应到相应的系列
						name: '有效消耗量',
						data: this.totalEffective5,
						markArea: {
							data: [
								[
									{
										name: '有效运动区间',
										yAxis: this.max5,
										label: {
											normal: {
												position: ['50%', '40%'],
												textStyle: {
													color: '#333',
												},
											},
										},
										itemStyle: {
											normal: {
												borderColor: '#fdad00',
												borderWidth: '1',
												borderType: 'dashed',
												color: '#fff',
											},
										},
									},
									{
										yAxis: this.min5,
									},
								],
							],
						},
					},
					{
						name: '收缩压',
						data: this.systolic5,
					},
					{
						name: '舒张压',
						data: this.diastolic5,
					},
					{
						name: '心率',
						data: this.heart5,
					},
				],
			});
			this.Chart6.hideLoading();
			this.Chart6.setOption({
				xAxis: [
					{
						data: this.timeData6,
					},
					{
						data: this.bloodTime6,
					},
				],
				series: [
					{
						// 根据名字对应到相应的系列
						name: '有效消耗量',
						data: this.totalEffective6,
						markArea: {
							data: [
								[
									{
										name: '有效运动区间',
										yAxis: this.max6,
										label: {
											normal: {
												position: ['50%', '40%'],
												textStyle: {
													color: '#333',
												},
											},
										},
										itemStyle: {
											normal: {
												borderColor: '#fdad00',
												borderWidth: '1',
												borderType: 'dashed',
												color: '#fff',
											},
										},
									},
									{
										yAxis: this.min6,
									},
								],
							],
						},
					},
					{
						name: '收缩压',
						data: this.systolic6,
					},
					{
						name: '舒张压',
						data: this.diastolic6,
					},
					{
						name: '心率',
						data: this.heart6,
					},
				],
			});
			this.Chart7.hideLoading();
			this.Chart7.setOption({
				xAxis: [
					{
						data: this.timeData7,
					},
					{
						data: this.bloodTime7,
					},
				],
				series: [
					{
						// 根据名字对应到相应的系列
						name: '有效消耗量',
						data: this.totalEffective7,
						markArea: {
							data: [
								[
									{
										name: '有效运动区间',
										yAxis: this.max7,
										label: {
											normal: {
												position: ['50%', '40%'],
												textStyle: {
													color: '#333',
												},
											},
										},
										itemStyle: {
											normal: {
												borderColor: '#fdad00',
												borderWidth: '1',
												borderType: 'dashed',
												color: '#fff',
											},
										},
									},
									{
										yAxis: this.min7,
									},
								],
							],
						},
					},
					{
						name: '收缩压',
						data: this.systolic7,
					},
					{
						name: '舒张压',
						data: this.diastolic7,
					},
					{
						name: '心率',
						data: this.heart7,
					},
				],
			});
		},
		handleChangeDate(value) {
			this.sportsData.select_date = value;
			this.handleInit();
		},
	},
	mounted() {
		this.handleInit();
		// echarts实例化
		this.Chart1 = echarts.init(this.$refs.chart1);
		this.Chart1.setOption(this.optionsInit());
		this.Chart1.showLoading();

		this.Chart2 = echarts.init(this.$refs.chart2);
		this.Chart2.setOption(this.optionsInit());
		this.Chart2.showLoading();

		this.Chart3 = echarts.init(this.$refs.chart3);
		this.Chart3.setOption(this.optionsInit());
		this.Chart3.showLoading();

		this.Chart4 = echarts.init(this.$refs.chart4);
		this.Chart4.setOption(this.optionsInit());
		this.Chart4.showLoading();

		this.Chart5 = echarts.init(this.$refs.chart5);
		this.Chart5.setOption(this.optionsInit());
		this.Chart5.showLoading();

		this.Chart6 = echarts.init(this.$refs.chart6);
		this.Chart6.setOption(this.optionsInit());
		this.Chart6.showLoading();

		this.Chart7 = echarts.init(this.$refs.chart7);
		this.Chart7.setOption(this.optionsInit());
		this.Chart7.showLoading();
	},
};
</script>
<style lang="css" scoped>
.scheme-contain .data-contain {border: none;}
.chart-info {color: #999;text-align: right;margin-bottom:10px;}
</style>
